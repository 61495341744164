<template>
  <editor :api-key="apikey" :init="config" v-model="inputVal" />
</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  data() {
    return {
      apikey: process.env.VUE_APP_TINYMCE_API_KEY,
      config: {
        language: "it",
        toolbar: "undo redo | tags",
        plugins: ["link charmap", "visualblocks", "paste", "wordcount"],
        paste_as_text: true,
        init_instance_callback: function (editor) {
          // console.log("test", editor);
          const wordCounter = editor
            .getContainer()
            .querySelector("button.tox-statusbar__wordcount");
          if (wordCounter) {
            const evt = new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            });
            // If cancelled, don't dispatch the event
            !wordCounter.dispatchEvent(evt);
          }
        },
        setup: function (editor) {
          editor.ui.registry.addMenuButton("tags", {
            text: "Tags",
            fetch: (callback) => {
              const items = [
                {
                  type: "menuitem",
                  text: "Data Odierna Estesa",
                  onAction: () => editor.insertContent("[[DATA_ODIERNA_LONG]]"),
                },
                {
                  type: "menuitem",
                  text: "Data Odierna",
                  onAction: () =>
                    editor.insertContent("[[DATA_ODIERNA_SHORT]]"),
                },
                {
                  type: "nestedmenuitem",
                  text: "Destinatario",
                  getSubmenuItems: function () {
                    return [
                      {
                        type: "menuitem",
                        text: "Nome",
                        onAction: () => editor.insertContent("[[NOME]]"),
                      },
                      {
                        type: "menuitem",
                        text: "Cognome",
                        onAction: () => editor.insertContent("[[COGNOME]]"),
                      },
                      {
                        type: "menuitem",
                        text: "PIVA",
                        onAction: () => editor.insertContent("[[PIVA]]"),
                      },
                      {
                        type: "menuitem",
                        text: "Codice Fiscale",
                        onAction: () => editor.insertContent("[[CF]]"),
                      },
                      {
                        type: "nestedmenuitem",
                        text: "Contatti",
                        getSubmenuItems: function () {
                          return [
                            {
                              type: "menuitem",
                              text: "Telefono",
                              onAction: () =>
                                editor.insertContent("[[TEL_MAIN]]"),
                            },
                            {
                              type: "menuitem",
                              text: "Cellulare",
                              onAction: () =>
                                editor.insertContent("[[CELL_MAIN]]"),
                            },
                            {
                              type: "menuitem",
                              text: "Email",
                              onAction: () =>
                                editor.insertContent("[[EMAIL_MAIN]]"),
                            },
                            {
                              type: "menuitem",
                              text: "Pec",
                              onAction: () =>
                                editor.insertContent("[[PEC_MAIN]]"),
                            },
                          ];
                        },
                      },
                      {
                        type: "nestedmenuitem",
                        text: "Indirizzo Principale",
                        getSubmenuItems: function () {
                          return [
                            {
                              type: "menuitem",
                              text: "Indirizzo",
                              onAction: () =>
                                editor.insertContent("[[INDIRIZZO_MAIN]]"),
                            },
                            {
                              type: "menuitem",
                              text: "Cap",
                              onAction: () =>
                                editor.insertContent("[[CAP_MAIN]]"),
                            },
                            {
                              type: "menuitem",
                              text: "Comune",
                              onAction: () =>
                                editor.insertContent("[[COMUNE_MAIN]]"),
                            },
                            {
                              type: "menuitem",
                              text: "Provincia",
                              onAction: () =>
                                editor.insertContent("[[PROVINCIA_MAIN]]"),
                            },
                          ];
                        },
                      },
                      {
                        type: "nestedmenuitem",
                        text: "Indirizzo Corrispondenza",
                        getSubmenuItems: function () {
                          return [
                            {
                              type: "menuitem",
                              text: "Indirizzo",
                              onAction: () =>
                                editor.insertContent("[[INDIRIZZO_CORR]]"),
                            },
                            {
                              type: "menuitem",
                              text: "Cap",
                              onAction: () =>
                                editor.insertContent("[[CAP_CORR]]"),
                            },
                            {
                              type: "menuitem",
                              text: "Comune",
                              onAction: () =>
                                editor.insertContent("[[COMUNE_CORR]]"),
                            },
                            {
                              type: "menuitem",
                              text: "Provincia",
                              onAction: () =>
                                editor.insertContent("[[PROVINCIA_CORR]]"),
                            },
                          ];
                        },
                      },
                    ];
                  },
                },
                {
                  type: "nestedmenuitem",
                  text: "Righe",
                  getSubmenuItems: function () {
                    return [
                      {
                        type: "menuitem",
                        text: "Data",
                        onAction: () => editor.insertContent("[[DATA_RIGA]]"),
                      },
                      {
                        type: "menuitem",
                        text: "Nominativo Contraente",
                        onAction: () =>
                          editor.insertContent(
                            "[[NOMINATIVO_CONTRAENTE_RIGA]]"
                          ),
                      },
                      {
                        type: "menuitem",
                        text: "Compagnia",
                        onAction: () =>
                          editor.insertContent("[[COMPAGNIA_RIGA]]"),
                      },
                      {
                        type: "menuitem",
                        text: "Numero Polizza",
                        onAction: () =>
                          editor.insertContent("[[NUMERO_POLIZZA_RIGA]]"),
                      },
                      {
                        type: "menuitem",
                        text: "Ramo",
                        onAction: () => editor.insertContent("[[RAMO_RIGA]]"),
                      },
                      {
                        type: "menuitem",
                        text: "Netto",
                        onAction: () => editor.insertContent("[[NETTO_RIGA]]"),
                      },
                      {
                        type: "menuitem",
                        text: "Tasse",
                        onAction: () => editor.insertContent("[[TASSE_RIGA]]"),
                      },
                      {
                        type: "menuitem",
                        text: "Lordo",
                        onAction: () => editor.insertContent("[[LORDO_RIGA]]"),
                      },
                    ];
                  },
                },
                {
                  type: "nestedmenuitem",
                  text: "Totale Righe",
                  getSubmenuItems: function () {
                    return [
                      {
                        type: "menuitem",
                        text: "Netto",
                        onAction: () => editor.insertContent("[[TOT_NETTO]]"),
                      },
                      {
                        type: "menuitem",
                        text: "Lordo",
                        onAction: () => editor.insertContent("[[TOT_LORDO]]"),
                      },
                    ];
                  },
                },
              ];
              callback(items);
            },
          });
          editor.on("input", function (event) {
            console.log("event", event);
            console.log("editor", editor);
            const content = editor.getContent({ format: "text" });
            if (content.length > 160) {
              editor.setContent(`<p>${content.slice(0, 160)}</p>`);
              editor.focus();
              editor.selection.select(editor.getBody(), true);
              editor.selection.collapse(false);
            }
          });
        },
        menubar: false,
        elementpath: false,
        content_style: "",
        branding: false,
      },
    };
  },
  props: {
    value: null,
    height: {
      type: Number,
      default: 100,
    },
  },
  components: {
    editor: Editor,
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    this.$set(this.config, "height", this.height);
  },
};
</script>

<style scoped>
.base-label:focus {
  outline: none !important;
}
</style>
